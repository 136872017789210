import React from 'react';
import styled from 'styled-components';
import {Title1, Title3, Body} from '../../../components/Typography';
import {PrimaryButton} from '../../../components/Buttons';
import appConfig from '../../../../app-config';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-family: ${props => props.theme.primaryFontFontFamily};

  @media screen and (max-width: 768px) {
    margin: 20px 0 0 0;
    flex-direction: column;
  }

  @media screen and (max-width: 360px) {
    margin: 0;
    flex-direction: column;
  }
`;

const Content = styled.div`
    width: ${props => props.width};
    margin: ${props => props.margin};

    @media screen and (max-width: 768px) {
      margin:10px 0 0 0;
      width: 382px;

    @media screen and (max-width: 480px) {
      margin:10px 0 0 0;
      align-items: center;
     width: 90%;
`;

const TitleWrapper = styled.div`
    width: ${props => props.width};
    margin: ${props => props.margin};

    @media screen and (max-width: 767px) {
      margin:10px 0 0 0;
`;

const ReferFriendTitle = styled(Title1)`
  padding-bottom: 10px;
  margin-top : 30px;
  text-transform: ${props => props.theme.pageTitleTextTransform};
  width: max-content;
  font-weight: 600;
  

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${props => props.categoriesBarSelectedBorderColorWidth} solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const ShareLinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f3f3f3;
  border-radius: 9px;
  //   padding: 3px;
`;

const CopyButton = styled(PrimaryButton)`
  height: 47px;
  min-width: 0;
  font-size: 19px;
  padding: 0 37px;
  align-self: center;

  @media (max-width: 768px) {
    width: auto;
  }
`;

const Emoji = styled.span`
  font-family: AppleColorEmoji;
  font-size: 106px;
`;

const Image = styled.img`
  width: ${appConfig.referFriendContentWidth || '81px'};
  margin: 35px 0 40px 0;
`;

const Icon = styled.i`
  color: ${props => props.theme.referFriendContentColor};
  justify-self: center;
  font-size: 81px;
  margin: 35px 0 40px 0;
`;

const ReferFriend = ({loyalty, props}) => {
  const newReferralMessage = loyalty?.referral_message.replace(
    '$$REFERRAL_CODE$$',
    loyalty.referral_code
  );

  const copyShareMessageToClipboard = () => {
    // Create textarea element
    const textarea = document.createElement('textarea');
    // Set the value of the text
    textarea.value = loyalty.referral_code;
    // Make sure we cant change the text of the textarea
    textarea.setAttribute('readonly', '');
    // Hide the textarea off the screen
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    // Add the textarea to the page
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
  };

  return (
    <Wrapper>
      <TitleWrapper margin={'0 70px 10px 0'}>
        <ReferFriendTitle id="ReferFriend-ReferFriendTitle">
          {' '}
          Refer a Friend
        </ReferFriendTitle>
      </TitleWrapper>
      <Content width={'400px'}>
        <Title3
          id="ProfileSettings-Title"
          style={{
            fontWeight: '600',
            letterSpacing: '0.03',
            marginBottom: '15px',
            marginTop:"30px"
          }}
        >
          Share the Love
        </Title3>
        {appConfig.referFriendContentType === 'emoji' ? (
          <Emoji role="img" aria-label="image">
            {appConfig.referFriendContent}
          </Emoji>
        ) : appConfig.referFriendContentType === 'image' ? (
          <Image src={appConfig.referFriendContent} />
        ) : appConfig.referFriendContentType === 'icon' ? (
          <Icon className={appConfig.referFriendContent} />
        ) : null}
        <Body
          style={{
            marginBottom: '50px',
            marginTop: '23px',
          }}
        >
          {newReferralMessage}
        </Body>
        <Title3
          id="ProfileSettings-Title"
          style={{
            fontWeight: '600',
            letterSpacing: '0.03',
            marginBottom: '18px',
          }}
        >
          Share Link
        </Title3>
        {loyalty && (
          <ShareLinkContainer>
            <Body
              style={{
                paddingLeft: '30px',
                alignSelf: 'center',
              }}
            >
              {loyalty.referral_code}
            </Body>
            <CopyButton onClick={copyShareMessageToClipboard}>Copy</CopyButton>
          </ShareLinkContainer>
        )}
      </Content>
    </Wrapper>
  );
};

export default ReferFriend;
