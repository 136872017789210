import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import ReferFriend from './components/ReferFriend';

const ReferFriendContainer = ({loyalty}) => {
  return (
    <Fragment>
      <ReferFriend loyalty={loyalty} />
    </Fragment>
  );
};

const mapStateToProps = state => ({
  loyalty: state.loyaltyReducer.loyalty,
});

export default connect(mapStateToProps)(ReferFriendContainer);
